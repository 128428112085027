import React from "react";

const DailyVotesIcon = () => {
	return (
		<svg
			id="assignment_black_24dp"
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
		>
			<path id="Path_49" data-name="Path 49" d="M0,0H30V30H0Z" fill="none" />
			<path
				id="Path_50"
				data-name="Path 50"
				d="M8.333,19.2h9.333v2.6H8.333Zm0-5.2H21.667v2.6H8.333Zm0-5.2H21.667v2.6H8.333Zm16-5.2H18.76a4.019,4.019,0,0,0-7.52,0H5.667a2.393,2.393,0,0,0-.533.052,2.7,2.7,0,0,0-1.347.715,2.6,2.6,0,0,0-.573.832A2.451,2.451,0,0,0,3,6.2V24.4a2.61,2.61,0,0,0,.213,1.014,2.752,2.752,0,0,0,.573.832,2.7,2.7,0,0,0,1.347.715A3.547,3.547,0,0,0,5.667,27H24.333A2.642,2.642,0,0,0,27,24.4V6.2A2.642,2.642,0,0,0,24.333,3.6ZM15,3.275a.975.975,0,1,1-1,.975A.995.995,0,0,1,15,3.275ZM24.333,24.4H5.667V6.2H24.333Z"
				fill="#7d7d7d"
			/>
		</svg>
	);
};

export default DailyVotesIcon;
